import { graphql } from "gatsby"
import React from "react"
import { slugify } from "../../../utils"
import VideoEmbed, { VideoEmbedProps } from "../../atoms/VideoEmbed"
import MediaBlock, { MediaBlockLayout } from "../../molecules/tileable-blocks/MediaBlock"
//import * as styles from "./ExternalVideo.module.scss"

const DatoExternalVideo = (props: Queries.DatoExternalVideoFragment) => {
  //console.log('Rendering DatoExternalVideo', props)
  return (
    <MediaBlock
      id={slugify(props.video?.title)}
      layout={props.layout as MediaBlockLayout}
      media={<VideoEmbed {...(props.video as VideoEmbedProps)} />}
    />
  )
}

export default DatoExternalVideo

export const query = graphql`
  fragment DatoExternalVideo on DatoCmsExternalVideo {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    video {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
    layout
  }
`
