import React from "react"
import * as styles from "./VideoEmbed.module.scss"

export type VideoEmbedProps = {
  url?: string
  title?: string
  provider: "youtube" | "vimeo" | "facebook" // ei varmuutta onko "vimeo" ja "facebook" oikein
  providerUid: string
  thumbnailUrl?: string
  width?: number
  height?: number
}

const VideoEmbed = (props: VideoEmbedProps) => {
  let video: JSX.Element | null = null

  //console.log("Video: ", props)
  const w = props.width || 640
  const h = props.height || 360
  const ar = w / h

  let arClass = styles.ar16x9
  if (ar < 1.7) arClass = styles.ar4x3
  if (ar < 1.3) arClass = styles.ar1x1
  if (ar < 1) arClass = styles.ar9x16

  switch (props.provider) {
    case "youtube":
      video = (
        <iframe
          title={props.title}
          src={`https://www.youtube.com/embed/${props.providerUid}`}
          width={w}
          height={h}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowFullScreen></iframe>
      )
      break
    case "vimeo":
      video = (
        <iframe
          title={props.title}
          src={`https://player.vimeo.com/video/${props.providerUid}`}
          width={w}
          height={h}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen></iframe>
      )
      break
    default:
      console.warn(`Unhandled VideoEmbed provider: ${props.provider}`)
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.videoContainer} ${arClass}`}>{video}</div>
    </div>
  )
}

export default VideoEmbed
